// src/stores/chat.store.ts
import { reactive, ref } from 'vue'
import { defineStore } from 'pinia'
import { useChatWrapperStore } from './chat-wrapper'
import {
  ActionStatus,
  type ActivateChatResponse,
  Events,
  type RemoveChatResponse
} from '@/modules/Ranger/services/interface'
import type { NewChatResponse, AddMessageResponse } from '@/modules/Ranger/services/interface'
import { useChatHistoryStore } from '@/modules/Ranger/stores/chat-history.ts'

export type ChatMessageType =
  | 'RangerMessageUser'
  | 'RangerMessageAssistance'
  | 'RangerMessageAction'

export interface ChatMessage {
  type: ChatMessageType
  content: string
  messageId?: string
}

export const useChatStore = defineStore('Chat', () => {
  const messages = reactive<ChatMessage[]>([])
  const currentChatId = ref<string | null>(null)
  const loading = ref(false)
  const isUseInitAScroll = ref(false) // added to support UI references
  const chatWrapperStore = useChatWrapperStore()
  const thinking = ref(false)
  const error = ref(false)

  function setThinking(value: boolean) {
    thinking.value = value
  }

  // Subscribe to live assistance updates from the shared client.
  async function subscribeToAssistance(): Promise<void> {
    const client = await chatWrapperStore.initOrGetClient()
    // Continue message (deltas)
    client.on(Events.continueMessage, (id, data) => {
      const payload = data as { messageId: string; delta?: string }
      const index = messages.findIndex((m) => m.messageId === payload.messageId)
      if (index !== -1) {
        // Append the new delta to the existing assistance message.
        messages[index].content += payload.delta || ''
      } else {
        // Create a new assistance message entry.
        messages.push({
          type: 'RangerMessageAssistance',
          content: payload.delta || '',
          messageId: payload.messageId
        })
        setThinking(false)
        loading.value = true
      }
    })
    // End message event (when the assistant message is complete)
    client.on(Events.endMessage, (id, payload) => {
      const typedPayload = payload as { messageId: string }
      console.log('Assistant message completed:', typedPayload.messageId)
      loading.value = false
    })
    // client.on(Events.processStart, () => {
    //   setThinking(true)
    // })
    // client.on(Events.processEnd, () => {
    //   setThinking(false)
    // })
    client.on(Events.chatNoteRequested, () => {
      messages.push({
        type: 'RangerMessageAction',
        content: ActionStatus.noteLoading
      })
    })
    client.on(Events.chatNoteCreated, () => {
      setTimeout(() => {
        const index = messages.findLastIndex((m) => m.type === 'RangerMessageAction')
        if (index >= 0) {
          messages[index].content = ActionStatus.noteLoaded
        }
      }, 100)
    })
    client.on(Events.error, () => {
      error.value = true
    })
  }

  /**
   * Creates a new chat with the provided first message.
   */
  async function newChat(firstMessage: string): Promise<NewChatResponse> {
    error.value = false
    messages.splice(0, messages.length)
    messages.push({
      type: 'RangerMessageUser',
      content: firstMessage
    })
    loading.value = true
    setThinking(true)
    const client = await chatWrapperStore.initOrGetClient()
    const response = await client.newChat(firstMessage)
    currentChatId.value = response.chatId
    // Clear previous messages and add the initial user message.
    messages.splice(0, messages.length)
    messages.push({
      type: 'RangerMessageUser',
      content: firstMessage
    })
    await subscribeToAssistance()
    await useChatHistoryStore().loadChats()
    return response
  }

  /**
   * Sends a new user message to the active chat.
   */
  async function sendMessage(content: string): Promise<AddMessageResponse> {
    if (!chatWrapperStore.rangerClient || !currentChatId.value) {
      throw new Error('No active chat or client not initialized')
    }
    loading.value = true
    setThinking(true)
    // Add the user message locally.
    messages.push({
      type: 'RangerMessageUser',
      content
    })
    const response = await chatWrapperStore.rangerClient.addMessage(currentChatId.value, content)
    return response
  }

  /**
   * Creates a user message locally.
   */
  function createUserMessage(content: string) {
    isUseInitAScroll.value = false
    if (currentChatId.value === null) {
      return newChat(content)
    } else {
      return sendMessage(content)
    }
  }

  function clearChat() {
    messages.splice(0, messages.length)
    loading.value = false
    currentChatId.value = null
  }

  async function loadChatFromId(chatId: string): Promise<ActivateChatResponse> {
    const client = await chatWrapperStore.initOrGetClient()
    const response = await client.activateChat(chatId)
    currentChatId.value = response.chatId
    // Optionally clear current messages (or merge with historical messages if available).
    messages.splice(0, messages.length)
    messages.push(
      ...response.chat.messages.map((m, i) => ({
        content: m.content,
        type: (m.type === 'user'
          ? 'RangerMessageUser'
          : 'RangerMessageAssistance') as ChatMessageType,
        messageId: i.toString()
      }))
    )
    await subscribeToAssistance()
    return response
  }

  /**
   * Removes the active chat.
   */
  async function removeChat(chatId: string): Promise<RemoveChatResponse> {
    if (!chatWrapperStore.rangerClient) {
      throw new Error('No active chat or client not initialized')
    }
    const response = await chatWrapperStore.rangerClient.removeChat(chatId)
    // If removal was successful, clear the active chat state.
    if (response.chatId === chatId) {
      currentChatId.value = null
      messages.splice(0, messages.length)
    }
    return response
  }

  function reset() {
    messages.splice(0, messages.length)
    currentChatId.value = null
    loading.value = false
    isUseInitAScroll.value = false
    error.value = false
  }

  return {
    messages,
    currentChatId,
    newChat,
    sendMessage,
    loading,
    thinking,
    isUseInitAScroll,
    createUserMessage,
    clearChat,
    loadChatFromId,
    removeChat,
    reset,
    error
  }
})
