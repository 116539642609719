export interface CreateCustomerSettingResponse<T = any> {
  id: string
  type: CustomSettingsType
  userId: string
  workspaceId?: string | null
  key: string
  value: T
  createdAt: string
}
export interface CreateCustomerSettingData<T = any> {
  id?: string
  type: CustomSettingsType
  key: string
  value: T
}

export type UpdateCustomerSettingData = Omit<CreateCustomerSettingData, 'id'> & {
  id: string
}

export enum CustomSettingsType {
  workspace = 'ws',
  userWorkspace = 'uws',
  user = 'u'
}
export interface FindCustomSetting {
  key: string
  type: CustomSettingsType
}
