import { defineStore, storeToRefs } from 'pinia'
import { useServices } from '@/lib/services'
import type {
  CreateCustomerSettingData,
  CreateCustomerSettingResponse,
  UpdateCustomerSettingData
} from '@/lib/api'
import { CustomSettingsType } from '@/lib/api'
import { useWorkspaceStore } from '@/modules/Workspace/store/useWorkspaceStore'
import { Modes } from '@/interfaces'

interface customSettingState {
  userCustomList: Array<CreateCustomerSettingResponse>
  workspaceSettingList: Array<CreateCustomerSettingResponse>
  userWsCustomList: Array<CreateCustomerSettingResponse>
}

export const useCustomSettingStore = defineStore('custom-setting', {
  state: (): customSettingState => ({
    userCustomList: [],
    workspaceSettingList: [],
    userWsCustomList: []
  }),

  getters: {
    isOnboardUser(): boolean {
      return (
        !!this.workspaceSettingList.find((item) => item.key === 'onboardingUser') ||
        !!this.userWsCustomList.find((item) => item.key === 'onboardingUser')
      )
    },
    isAddFirstCamera(): boolean {
      return (
        !!this.workspaceSettingList.find((item) => item.key === 'addFirstCamera') ||
        !!this.userWsCustomList.find((item) => item.key === 'addFirstCamera')
      )
    }
  },

  actions: {
    async getUserListCustom() {
      this.userCustomList = await useServices().customSetting.list(CustomSettingsType.user)
    },

    async getWorkspaceListCustom() {
      this.workspaceSettingList = await useServices().customSetting.list(
        CustomSettingsType.workspace
      )
    },

    async getUserWorkspaceListCustom(force: boolean = false) {
      if (this.userWsCustomList.length === 0 || force) {
        this.userWsCustomList = await useServices()
          .customSetting.list(CustomSettingsType.userWorkspace)
          .then(async (res) => {
            const founded = res.find((uws) => uws.key === 'securitySettings')
            if (founded) {
              await useServices().customSetting.remove(founded.id)
            }
            return res
          })
      }
    },

    async addWorkspaceSetting(data) {
      // await this.filterBeforeAddSetting(data.key)
      const response = await useServices().customSetting.create(data)
      this.userWsCustomList.push(response)
      return response
    },

    async addOrUpdateSetting(data: CreateCustomerSettingData) {
      const oldOne = this.workspaceSettingList.find((setting) => setting.key === data.key)
      if (oldOne) {
        const response = await this.update({ id: oldOne.id, ...data })
        return response
      } else {
        const response = await useServices().customSetting.create(data)
        this.pushToList(response)
        return response
      }
    },

    pushToList(response: CreateCustomerSettingResponse) {
      switch (response.type) {
        case CustomSettingsType.user:
          this.userCustomList.push(response)
          return response
        case CustomSettingsType.workspace:
          this.workspaceSettingList.push(response)
          return response
        case CustomSettingsType.userWorkspace:
          this.userWsCustomList.push(response)
          return response
      }
    },

    async update(setting: UpdateCustomerSettingData) {
      return useServices()
        .customSetting.update(setting.id, setting)
        .then((res) => {
          switch (res.type) {
            case CustomSettingsType.user:
              this.userCustomList = this.userCustomList.map((item) =>
                item.id === setting.id ? { ...item, ...setting } : item
              )
              return this.userCustomList.find((item) => item.id === setting.id)
            case CustomSettingsType.workspace:
              this.workspaceSettingList = this.workspaceSettingList.map((item) =>
                item.id === setting.id ? { ...item, ...setting } : item
              )
              return this.workspaceSettingList.find((item) => item.id === setting.id)
            case CustomSettingsType.userWorkspace:
              this.userWsCustomList = this.userWsCustomList.map((item) =>
                item.id === setting.id ? { ...item, ...setting } : item
              )
              return this.userWsCustomList.find((item) => item.id === setting.id)
          }
        })
    },

    async filterBeforeAddSetting(key: string) {
      const existSetting = this.userWsCustomList.filter((setting) => setting.key === key)
      const promises = existSetting.map((setting) => this.removeWorkspaceSetting(setting.id))
      await Promise.all(promises)
    },

    async removeWorkspaceSetting(id: string) {
      await useServices().customSetting.remove(id)
      this.userWsCustomList = this.userWsCustomList.filter((item) => item.id !== id)
    },

    async createFirstAddCameraSetting() {
      const currentWorkspace = useWorkspaceStore().currentWorkspace
      if (!this.isAddFirstCamera && currentWorkspace) {
        const data = {
          type: CustomSettingsType.workspace,
          key: 'addFirstCamera',
          value: 'true',
          userId: currentWorkspace.ownerId,
          workspaceId: currentWorkspace.id
        }
        await this.addWorkspaceSetting(data)
        await this.getWorkspaceListCustom()
      }
    },

    async workspaceIsOnboarded() {
      const currentWorkspace = useWorkspaceStore().currentWorkspace
      if (String(import.meta.env.MODE ?? Modes.main) !== Modes.local && currentWorkspace) {
        const data = {
          type: CustomSettingsType.userWorkspace,
          key: 'onboardingUser',
          value: 'true',
          userId: currentWorkspace.ownerId,
          workspaceId: currentWorkspace.id
        }
        await this.addWorkspaceSetting(data)
        await this.getUserWorkspaceListCustom(true)
      }
    }
  }
})
