// src/stores/chatHistory.store.ts
import { reactive } from 'vue'
import { defineStore } from 'pinia'
import { useChatWrapperStore } from './chat-wrapper'
import type { UserChatEntity, RetrieveChatsResponse } from '@/modules/Ranger/services/interface'

export const useChatHistoryStore = defineStore('chatHistory', () => {
  const chats = reactive<UserChatEntity[]>([])
  const chatWrapperStore = useChatWrapperStore()

  async function loadChats(): Promise<void> {
    const client = await chatWrapperStore.initOrGetClient()
    const response: RetrieveChatsResponse = await client.getAllChats()
    // Assuming response.chats is an object with a property 'chats' as an array.
    if (response && response.chats) {
      chats.splice(0, chats.length)
      chats.push(...response.chats.chats)
      chats.sort((c1, c2) => c2.createdAt - c1.createdAt)
    }
  }

  function reset() {
    chats.splice(0, chats.length)
  }

  return { chats, loadChats, reset }
})
