export enum Events {
  newChat = 'newChat',
  removeChat = 'removeChat',
  renameChat = 'renameChat',
  activateChat = 'activateChat',
  retrieveChats = 'retrieveChats',
  addMessage = 'addMessage',
  chatNoteRequested = 'chatNoteRequested',
  chatNoteCreated = 'chatNoteCreated',
  processStart = 'processStart',
  processEnd = 'processEnd',
  continueMessage = 'continueMessage',
  endMessage = 'endMessage',
  retrieveAlerts = 'retrieveAlerts',
  error = 'error'
}

export enum ActionStatus {
  thinking = 'thinking',
  noteLoading = 'noteLoading',
  noteLoaded = 'noteLoaded'
}

export interface MessageType {
  id: string
  event: string
  payload: unknown
}

export interface UserChat {
  userId: string //uuid
  chats: Array<UserChatEntity>
}

// Example type definitions for chat-related responses.
// Adjust these definitions as needed.
export interface UserChatEntity {
  chatId: string
  title: string
  createdAt: number
}

export interface UserChat {
  userId: string
  chats: UserChatEntity[]
}

export interface NewChatResponse {
  chatId: string
}

export interface RemoveChatResponse {
  chatId: string
}

export interface RenameChatResponse {
  chatId: string
  newTitle: string
}

export interface Message {
  content: string
  data: any
  type: 'user' | 'assistant'
  createdAt: number
}

export interface NoteRequests {
  cameraId: string
  date: number
}

export interface Chat {
  chatId: string //uuid
  lastUpdate: number
  noteRequests: NoteRequests[]
  messages: Array<Message>
}

export interface ActivateChatResponse {
  chatId: string
  chat: Chat
}

export interface AddMessageResponse {
  chatId: string
  content: string
}

export interface RetrieveChatsResponse {
  chats: UserChat
}

export interface AlertObject {
  cameras: string[]
  content: {
    notification: {
      message: {
        severityLevel: number
        description: string
        title: string
      }
    }
  }
  createdAt: number
  why: {
    notification: {
      why: string
    }
  }
  workspaceId: string
}

export interface RetrieveAlertsResponse {
  alerts: Array<AlertObject>
}
