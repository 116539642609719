import { defineStore } from 'pinia'
import { useServices } from '@/lib/services'
import { groupBy, orderBy } from 'lodash'
import { NormalizeData } from '@/utils/normalize'
import { updatedItem } from '@/stores/StoreUtils'
import { NotificationDisplayTypes, NotificationFilterTypes } from './userNotification.interface'
import type { NotificationState, NotifierUserSettingsMapped } from './userNotification.interface'
import {
  type NotificationFindDto,
  NotificationNames,
  NotificationTypes,
  NotificationUpdateInterval,
  NotifierResourceTypes
} from '@/lib/api'
import type {
  Notification,
  NotificationUserSettings,
  NotificationUserSettingsResponse,
  NotifierPayload
} from '@/lib/api'
import { FilterGroups } from '@/lib/api'

const notificationGroups = {
  all: [],

  camera: [
    NotificationNames.cameraCreationFailed,
    NotificationNames.cameraRecordingPaused,
    NotificationNames.cameraRecordingResumed,
    NotificationNames.cameraHealthHealthy,
    NotificationNames.cameraHealthUnhealthy
  ],
  aiEvents: [
    NotificationNames.alertingHwMotion,
    NotificationNames.alertingSwMotion,
    NotificationNames.alertingHwAudio,
    NotificationNames.alertingSwAudio,
    NotificationNames.alertingHwFence,
    NotificationNames.alertingSwFence,
    NotificationNames.alertingHwCrossLine,
    NotificationNames.alertingSwCrossLine,
    NotificationNames.alertingSwCrossLineIn,
    NotificationNames.alertingSwCrossLineOut
  ],
  payment: [
    NotificationNames.paymentActionRequired,
    NotificationNames.paymentRenewalPaused,
    NotificationNames.paymentTrialEnding,
    NotificationNames.paymentTrialEnded,
    NotificationNames.paymentInvoicePaid,
    NotificationNames.paymentPmExpiring
  ],
  security: [
    NotificationNames.alertingHwTampering,
    NotificationNames.alertingSwTampering,
    NotificationNames.alertingSwLeftAndMissed
  ],
  others: [
    NotificationNames.workspaceInviteUser,
    NotificationNames.workspaceInviteAccepted,
    NotificationNames.workspaceInviteCancelled,
    NotificationNames.workspaceUserRemoved,
    NotificationNames.workspaceUserSuspended,
    NotificationNames.permissionSuspendTeam,
    NotificationNames.userEventSessionActivated,
    NotificationNames.authenticationUpdatePhone,
    NotificationNames.authenticationChangePhoneAttempt,
    NotificationNames.authenticationUpdateEmail,
    NotificationNames.authenticationPasswordUpdated,
    NotificationNames.authenticationSuspiciousActivity,
    NotificationNames.authenticationSuccessfulRegister,
    NotificationNames.authenticationSuccessfulLogin,
    NotificationNames.authenticationActivateMFA,
    NotificationNames.exportExportFailed,
    NotificationNames.exportExportFinished,
    NotificationNames.analyticOrchestrateAnalyticIsReady
  ]
}

export const useNotificationStore = defineStore('notificationStore', {
  state: (): NotificationState => ({
    service: useServices().notification,
    userService: useServices().notificationUserService,
    notifications: {
      [NotificationDisplayTypes.ALL]: [],
      [NotificationDisplayTypes.CLEARED]: []
    },

    pageNumbers: {
      [NotificationDisplayTypes.ALL]: 0,
      [NotificationDisplayTypes.CLEARED]: 0
    },

    filters: {
      [NotificationDisplayTypes.ALL]: {
        [NotificationFilterTypes.type]: FilterGroups.all
      },

      [NotificationDisplayTypes.CLEARED]: {
        [NotificationFilterTypes.type]: FilterGroups.all
      }
    },

    limit: 10,
    offset: 0,
    offsetUser: 0,
    isLastPage: false,
    isLastPageInUser: false,
    userSettings: [],
    loading: false,
    drawer: {
      open: false
    }
  }),

  getters: {
    getDefaultInterval: (state) => (group: string) => {
      const setting = state.userSettings
        .filter(
          (s) =>
            s.notificationGroup[NotificationFilterTypes.type] === group &&
            s.notificationType !== NotificationTypes.email
        )
        .map((s) => s.interval)
      const value = setting.find(
        (interval) => interval !== undefined && !isNaN(Number(interval)) && Number(interval) > 0
      )
      const numberValue = Number(`${value}`)
      return !isNaN(numberValue) ? numberValue : 0
    },

    haveUnreadNotification() {
      return (type: NotificationDisplayTypes) => {
        if (this.notifications[type]) {
          return !!this.notifications[type].find((item) => !item.readAt)
        } else {
          return false
        }
      }
    },

    groupSettings(state) {
      return (group: FilterGroups) => {
        const filteredNotifications = state.userSettings.filter(
          (notification) => notification.notificationGroup[NotificationFilterTypes.type] === group
        )

        // Reduce to create the desired object
        const result = filteredNotifications.reduce(
          (acc, notification) => {
            const { notificationType } = notification

            // Count occurrences of each notificationType
            acc[notificationType] = (acc[notificationType] || 0) + 1

            return acc
          },

          {} as Record<string, number>
        )

        // Convert the counts to booleans based on whether the count is equal to 10
        return Object.keys(result).reduce(
          (acc, key) => {
            acc[key] = result[key] === notificationGroups[group].length
            return acc
          },

          {} as Record<string, boolean>
        )
      }
    },

    activeFilter(state) {
      return (type: NotificationDisplayTypes) => state.filters[type]
    },

    filterList() {
      return (type: NotificationDisplayTypes) =>
        notificationGroups[this.filters[type][NotificationFilterTypes.type] || -1]?.length > 0
          ? notificationGroups[this.filters[type][NotificationFilterTypes.type] || -1]
          : undefined
    },

    notificationsList() {
      return (type: NotificationDisplayTypes) => {
        return groupBy(
          orderBy(this.notifications[type], 'createdAt', 'desc').map((item) => ({
            ...item,
            createdAtNormalize: String(NormalizeData.standardDate(String(item.createdAt), 'en'))
          })),
          'createdAtNormalize'
        )
      }
    },

    showDrawer(state) {
      return state.drawer.open
    }
  },

  actions: {
    async getNote(id: string, createdAt: Date) {
      let note = ''
      if (createdAt.getTime() < Date.now() - 30_000) {
        note = await this.service.sliderText(id)
        for (const notifs in this.notifications) {
          this.notifications[notifs].forEach((notif) => {
            if (notif.id === id) {
              notif.note = note
            }
          })
        }
      }
      return note
    },

    triggerDrawer(state: boolean = true) {
      this.drawer.open = state
    },

    reset() {
      this.resetAllNotificationsList()
      this.limit = 10
      this.offset = 0
      this.offsetUser = 0
      this.isLastPage = false
      this.isLastPageInUser = false
      this.userSettings = []
      // this.currentUserSchedule = []
    },

    resetAllNotificationsList() {
      this.resetNotificationList(NotificationDisplayTypes.ALL)
      this.resetNotificationList(NotificationDisplayTypes.CLEARED)
      this.filters = {
        [NotificationDisplayTypes.ALL]: {
          [NotificationFilterTypes.type]: FilterGroups.all
        },

        [NotificationDisplayTypes.CLEARED]: {
          [NotificationFilterTypes.type]: FilterGroups.all
        }
      }
    },

    changeActiveFilter(
      filter: FilterGroups | string | undefined,
      type: NotificationDisplayTypes,
      filterType = NotificationFilterTypes.type,
      read?: boolean
    ) {
      this.filters[type][filterType] = filter
      this.resetNotificationList(type)
      this.fetchNotifications(type, read)
    },

    resetNotificationList(type: NotificationDisplayTypes) {
      this.notifications[type] = []
      this.pageNumbers[type] = 0
    },

    async loadNextPage(type: NotificationDisplayTypes, read?: boolean) {
      await this.fetchNotifications(type, read, true)
    },

    buildQuery(type: NotificationDisplayTypes, read?: boolean): NotificationFindDto {
      const result: NotificationFindDto = {
        clear: type === NotificationDisplayTypes.CLEARED ? false : undefined,
        read
      }
      const filters = this.filters[type]
      if (filters[NotificationFilterTypes.type]) {
        result.name = this.filterList(type)
      }
      if (filters[NotificationFilterTypes.cameraId]) {
        result.resourceId = filters[NotificationFilterTypes.cameraId]
        result.resourceType = NotifierResourceTypes.Camera
      }
      if (filters[NotificationFilterTypes.date]) {
        result.before = filters[NotificationFilterTypes.date]
      }
      return result
    },

    serializer(notifications: Array<Notification> | Notification) {
      if (Array.isArray(notifications)) {
        return notifications.map((notif) => ({
          ...notif,
          isSlider:
            notif.action?.resourceType === 'c' &&
            typeof notif.name === 'string' &&
            (notif.name.includes('camera-health:ch-unhealthy') || notif.name.includes('alerting'))
        }))
      } else {
        return {
          ...notifications,
          isSlider:
            notifications.action?.resourceType === 'c' &&
            typeof notifications.name === 'string' &&
            (notifications.name.includes('camera-health:ch-unhealthy') ||
              notifications.name.includes('alerting'))
        }
      }
    },

    async fetchNotification(id: string, type?: NotificationDisplayTypes) {
      const fetchedNotification = this.serializer(await this.service.find(id)) as Notification
      if (type) {
        this.notifications[type] = [fetchedNotification, ...this.notifications[type]]
      } else {
        for (const notifType in this.notifications) {
          this.notifications[notifType].push(fetchedNotification)
        }
      }
    },

    async fetchNotificationByFilters(id: string, type: NotificationDisplayTypes) {
      const fetchedNotification = this.serializer(
        await this.service.findAdvance(
          this.buildQuery(type as NotificationDisplayTypes, false),
          1,
          [id]
        )
      ) as Array<Notification>
      this.notifications[type].push(...fetchedNotification)
    },

    async fetchNotificationByEvent(id: string) {
      await Promise.all([
        Object.keys(this.notifications).map((n) =>
          this.fetchNotificationByFilters(id, n as NotificationDisplayTypes)
        )
      ])
    },

    async fetchNotifications(
      type: NotificationDisplayTypes,
      read?: boolean,
      loadMore: boolean = false
    ) {
      if (this.pageNumbers[type] === 0 || loadMore) {
        this.loading = true
        try {
          if (this.pageNumbers[type] === 0) {
            this.notifications[type] = []
          }

          this.pageNumbers[type] += 1
          const fetchedData = this.serializer(
            await this.service.findAdvance(this.buildQuery(type, read), this.pageNumbers[type])
          ) as Array<Notification>

          this.notifications[type] = [...this.notifications[type], ...fetchedData]

          if (fetchedData.length === 0) this.pageNumbers[type] -= 1
          this.isLastPage = fetchedData.length < 20
        } catch (error) {
          console.error('Error loading notifications:', error)
        } finally {
          this.loading = false
        }
      }
    },

    async markAsSeen(id: string) {
      const data = this.serializer(await this.service.markAsRead(id))
      for (const group in this.notifications) {
        updatedItem(this.notifications[group], data)
      }
    },

    async markAsCleared(id: string) {
      await this.service.markAsCleared(id)
      this.notifications[NotificationDisplayTypes.CLEARED] = this.notifications[
        NotificationDisplayTypes.CLEARED
      ].filter((notif) => {
        return notif.id !== id
      })
    },

    // Notifier settings
    async getUserSettings() {
      const notificationSettings = await useServices().notificationUserService.getCurrent()
      this.userSettings = this.settingsSetializer(notificationSettings)
    },

    pushToUserSettings(newSetting: NotifierUserSettingsMapped) {
      if (!this.userSettings) {
        this.userSettings = []
      }

      // Get the index of the existing setting based on `id`
      const index = this.userSettings.findIndex((setting) => setting.id === newSetting.id)

      if (index === -1) {
        // If the setting does not exist, push the new setting
        this.userSettings.push(newSetting)
      } else {
        // If the setting exists, update it at the found index
        this.userSettings[index] = newSetting
      }
    },

    settingsSetializer(settings: Array<NotificationUserSettingsResponse>) {
      return settings.map((setting) => {
        let groupName = ''
        for (const group in notificationGroups) {
          const has = notificationGroups[group].includes(setting.notificationName)
          if (has) {
            groupName = group
            break
          }
        }
        const defaultInterval = NotificationUpdateInterval.normal
        return {
          ...setting,
          notificationGroup: FilterGroups[groupName],
          interval: setting.interval || defaultInterval
        }
      })
    },

    isCheckCameraHealth(notificationNames: string[]): boolean {
      return notificationNames.some(
        (name) =>
          name === NotificationNames.cameraHealthHealthy ||
          name === NotificationNames.cameraHealthUnhealthy
      )
    },

    async removeNotificationsByGroup(data: NotificationUserSettings) {
      const settings = this.userSettings
      if (settings) {
        const ids = settings
          .filter(
            (setting) => setting.notificationGroup[NotificationFilterTypes.type] === data.group
          )
          .map(({ id }) => id)
        this.userService.remove(ids)
        this.filterUserSettings(data)
      }
    },

    async turnOffNotifications(data: NotificationUserSettings) {
      const settings = this.userSettings
      if (settings) {
        try {
          const ids = settings
            .filter(
              (setting) =>
                setting.notificationGroup[NotificationFilterTypes.type] === data.group &&
                setting.notificationType === data.notificationType
            )
            .map(({ id }) => id)
          this.userService.remove(ids)
          this.filterUserSettings(data)
        } catch (e) {
          console.log(e)
        }
      }
    },

    filterUserSettings(data: NotificationUserSettings) {
      this.userSettings = this.userSettings.filter(
        (setting) =>
          !(
            setting.notificationGroup[NotificationFilterTypes.type] == data.group &&
            (setting.notificationType === data.notificationTypes ||
              setting.notificationType === data.notificationType)
          )
      )
    },

    async turnOnNotifications(data: NotifierPayload, isMute: boolean = false) {
      try {
        const isCameraHealth = this.isCheckCameraHealth(notificationGroups[data.group])
        const type = data.type
        const direct = isMute && isCameraHealth
        const notificationSetting: any = {
          notificationName: notificationGroups[data.group],
          notificationType: data.notificationType,
          type,
          interval: data.interval,
          direct
        }
        const newSettings = await this.userService.create(notificationSetting)
        this.userSettings.push(...this.settingsSetializer(newSettings))
      } catch (error) {
        console.error('Error in turnOnNotifications:', error)
      }
    },

    async turnOnNotificationsBulk(data: any, isMute: boolean = false) {
      try {
        const isCameraHealth = this.isCheckCameraHealth(notificationGroups[data.group])
        const type = data.type
        const direct = isMute && isCameraHealth
        const notificationSetting: any = {
          notificationName: notificationGroups[data.group],
          notificationTypes: data.notificationType,
          type,
          interval: data.interval,
          direct
        }
        const newSettings = await this.userService.createBulk(notificationSetting)
        this.settingsSetializer(newSettings).forEach((s) => {
          this.pushToUserSettings(s)
        })
      } catch (error) {
        console.error('Error in turnOnNotifications:', error)
      }
    },

    async updateMuteIntervalBulk(data: any, isMute: boolean = false) {
      try {
        const { group, type, inactiveNotificationType, interval } = data
        const isCameraHealth = this.isCheckCameraHealth(notificationGroups[group])
        const direct = isMute && isCameraHealth

        const notificationSetting: any = {
          notificationName: notificationGroups[group],
          notificationTypes: inactiveNotificationType,
          type,
          interval: interval,
          direct
        }
        const newSettings = await this.userService.update(notificationSetting)
        this.settingsSetializer(newSettings).forEach((s) => {
          this.pushToUserSettings(s)
        })
      } catch (error) {
        console.error('Failed to update mute interval settings:', error)
      }
    },

    async updateNotifier(data: NotificationUserSettings, isMute: boolean = false) {
      try {
        const isCameraHealth = this.isCheckCameraHealth(notificationGroups[data.group])
        const type = 1
        const direct = isMute && isCameraHealth

        const notificationSetting: any = {
          notificationName: notificationGroups[data.group],
          notificationType: data.notificationType,
          type,
          interval: data.interval,
          direct
        }
        const newSettings = await this.userService.updateSingle(notificationSetting)
        this.userSettings.push(...this.settingsSetializer(newSettings))
      } catch (error) {
        console.error('Failed to update mute interval settings:', error)
      }
    },

    async clearAllNotifications() {
      await this.service.markAsClearedAll()
      this.pageNumbers[NotificationDisplayTypes.CLEARED] = 0
      this.notifications[NotificationDisplayTypes.CLEARED] = []
    }
  }
})
