// @ts-ignore
import { createI18n } from 'vue-i18n'
import EnMessage from '../i18n/lang/en'
import all from '@/assets/lang/en/index.json'
export const i18n = createI18n({
  locale: 'en',
  messages: {
    en: { ...EnMessage, ...all }
  }
})
