import { useServices } from '@/lib/services.ts'
import { Events } from '@/modules/Ranger/services/interface.ts'
import type {
  MessageType,
  NewChatResponse,
  RemoveChatResponse,
  RenameChatResponse,
  ActivateChatResponse,
  AddMessageResponse,
  RetrieveChatsResponse,
  RetrieveAlertsResponse,
  UserChat
} from '@/modules/Ranger/services/interface.ts'

export class RangerClient {
  protected socket!: WebSocket
  protected idListeners: Map<string, (msg: MessageType) => any> = new Map()
  protected eventsListeners: Map<string, Array<(id: string, payload: unknown) => any>> = new Map()

  protected makeId(length = 12): string {
    let result = ''
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    const charactersLength = characters.length
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength))
    }
    return result
  }

  protected async getToken(): Promise<string> {
    const { token } = await useServices().ai.ranger.getSocketToken()
    return token
  }

  public static getStatus() {
    return useServices().ai.ranger.getStatus()
  }

  protected async getServerAddress(): Promise<string> {
    const token = await this.getToken()
    const protocol = window.location.protocol === 'https:' ? 'wss://' : 'ws://'
    return protocol + window.location.host + `/api/orbit/v1/socket?token=${token}`
  }

  static async connect(
    options: { retry?: boolean; maxRetries?: number; retryInterval?: number } = {}
  ): Promise<RangerClient> {
    const client = new RangerClient()
    await client.connect(options)
    return client
  }

  protected async connect(
    options: { retry?: boolean; maxRetries?: number; retryInterval?: number } = {}
  ): Promise<void> {
    const { retry = false, maxRetries = 3, retryInterval = 1000 } = options
    let attempts = 0

    while (attempts < maxRetries) {
      try {
        this.disconnect() // Clean up previous socket if any
        const serverAddress = await this.getServerAddress()
        this.socket = new WebSocket(serverAddress)
        this.socket.onmessage = this.onMessageBound

        // Wait for the socket to open or error out.
        await new Promise<void>((resolve, reject) => {
          this.socket.onopen = () => resolve()
          this.socket.onerror = (err) => reject(err)
        })

        // Connected successfully.
        return
      } catch (error) {
        attempts++
        console.error(`Attempt ${attempts} failed:`, error)
        if (!retry || attempts >= maxRetries) {
          throw error
        }
        await new Promise((resolve) => setTimeout(resolve, retryInterval))
      }
    }
  }

  public disconnect(): void {
    if (
      this.socket &&
      this.socket.readyState !== WebSocket.CLOSED &&
      this.socket.readyState !== WebSocket.CLOSING
    ) {
      this.socket.close()
    }
  }

  protected onMessageBound = this.onMessage.bind(this)

  protected onMessage({ data, type }: MessageEvent): void {
    if (type === 'message' || typeof data === 'string') {
      const { payload, id, event } = JSON.parse(data) as MessageType
      const eventListeners = this.eventsListeners.get(event)
      if (eventListeners && eventListeners.length > 0) {
        eventListeners.forEach((listener) => listener(id, payload))
      }
      const idListener = this.idListeners.get(id)
      if (idListener) {
        idListener({ payload, id, event })
      }
    } else {
      console.warn('Unexpected binary data received!')
    }
  }

  protected send(id: string, event: Events, payload: unknown): void {
    this.socket.send(JSON.stringify({ id, event, payload }))
  }

  public async requests(event: Events, payload: unknown = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      const id = this.makeId()
      this.idListeners.set(id, (msg: MessageType) => {
        resolve(msg.payload)
        this.idListeners.delete(id)
      })
      this.send(id, event, payload)
    })
  }

  public on(event: Events, cb: (id: string, payload: unknown) => any): void {
    let cbs = this.eventsListeners.get(event)
    if (cbs) {
      if (cbs.length > 0) {
        // for now
        cbs[0] = cb
      } else {
        cbs.push(cb)
      }
    } else {
      this.eventsListeners.set(event, [cb])
    }
  }

  // --- API Methods for each event ---

  /**
   * Creates a new chat with the given first message.
   */
  public async newChat(message: string): Promise<NewChatResponse> {
    return this.requests(Events.newChat, message)
  }

  /**
   * Removes a chat by chatId.
   */
  public async removeChat(chatId: string): Promise<RemoveChatResponse> {
    return this.requests(Events.removeChat, { chatId })
  }

  /**
   * Renames a chat.
   */
  public async renameChat(chatId: string, newTitle: string): Promise<RenameChatResponse> {
    return this.requests(Events.renameChat, { chatId, newTitle })
  }

  /**
   * Activates a chat.
   */
  public async activateChat(chatId: string): Promise<ActivateChatResponse> {
    return this.requests(Events.activateChat, { chatId })
  }

  /**
   * Retrieves all chats for the user.
   */
  public async getAllChats(): Promise<RetrieveChatsResponse> {
    return this.requests(Events.retrieveChats)
  }

  /**
   * Adds a message to an active chat.
   */
  public async addMessage(chatId: string, content: string): Promise<AddMessageResponse> {
    return this.requests(Events.addMessage, { chatId, content })
  }

  /**
   * Retrieves alerts.
   */
  public retrieveAlerts(
    before: number,
    hasContent?: boolean,
    count?: number
  ): Promise<RetrieveAlertsResponse> {
    return this.requests(Events.retrieveAlerts, { before, hasContent, count })
  }

  /**
   * Subscribes to continueMessage events (e.g. for deltas or progress updates).
   */
  public onContinueMessage(
    cb: (id: string, payload: { messageId: string; delta?: string }) => any
  ): void {
    this.on(Events.continueMessage, (id, payload) => {
      cb(id, payload as { messageId: string; delta?: string })
    })
  }
}
