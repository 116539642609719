import { Service } from '@/lib/api'

export interface sessionSocketToken {
  token: string
}

export interface CaptureConfig {
  workspaceId: string // uuid
  cameras: Array<string> // uuid
  policies: Array<{
    detail: string
    priority: number
  }>
  captureServer: string
}

export class RangerService extends Service {
  public getSocketToken() {
    return this.post<sessionSocketToken>(`/api/orbit/v1/authentication/token`)
  }
  public getStatus() {
    return this.get<CaptureConfig>(`/api/orbit/v1/authentication/status`)
  }
}
