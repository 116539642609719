import { HeatmapService, Service } from '@/lib/api'
import type { AxiosInstance } from 'axios'
import { TrackerService } from '@/lib/api/services/ai/tracker'
import { ChatService } from '@/lib/api/services/ai/chat/chat.service'
import { AnalyticEventService } from '@/lib/api/services/ai/analytics/event.service'
import { AlertService } from '@/lib/api/services/ai/alert/alert.service'
import { AnalyticService } from '@/lib/api/services/ai/analytics/analytic.service'
import { RangerService } from '@/lib/api/services/ai/ranger/RangerService.ts'

export class AIManagerService extends Service {
  heatmap: HeatmapService
  tracker: TrackerService
  chat: ChatService
  event: AnalyticEventService
  alert: AlertService
  analytic: AnalyticService
  ranger: RangerService

  constructor(protected readonly adaptor: AxiosInstance) {
    super(adaptor)
    this.heatmap = new HeatmapService(adaptor)
    this.tracker = new TrackerService(adaptor)
    this.chat = new ChatService(adaptor)
    this.event = new AnalyticEventService(adaptor)
    this.alert = new AlertService(adaptor)
    this.analytic = new AnalyticService(adaptor)
    this.ranger = new RangerService(adaptor)
  }
}
