// src/stores/chat-wrapper.ts
import { computed, ref } from 'vue'
import { defineStore } from 'pinia'
import { RangerClient } from '@/modules/Ranger/services/ranger.client'
import { Events } from '@/modules/Ranger/services/interface'
import { usePaymentStore } from '@/stores/payment/usePaymentStore.ts'

export type RangerClientPublic = Pick<
  RangerClient,
  | 'newChat'
  | 'addMessage'
  | 'getAllChats'
  | 'onContinueMessage'
  | 'on'
  | 'disconnect'
  | 'removeChat'
  | 'activateChat'
  | 'retrieveAlerts'
  | 'renameChat'
>

export const useChatWrapperStore = defineStore('chatWrapper', () => {
  const isEnabled = ref(false)
  const rangerClient = ref<RangerClientPublic | null>(null)
  const alertVisible = ref(false)
  const paymentDialog = ref(true)
  const payment = usePaymentStore()

  async function initOrGetClient(): Promise<RangerClientPublic> {
    if (!rangerClient.value) {
      // Cast the returned instance to your public interface.
      rangerClient.value = (await RangerClient.connect({ retry: true })) as RangerClientPublic
    }
    return rangerClient.value
  }

  const isPayedService = computed(() => {
    const subMetadata = payment.activeSubscription?.data?.plan?.metadata
    return !!(subMetadata?.AI && subMetadata.AI.toLowerCase().includes('ranger'))
  })

  async function checkPaymentStatus() {
    if (isPayedService.value) {
      return true
    } else {
      try {
        const data = await RangerClient.getStatus()
        return data && data.cameras.length > 0
      } catch (e) {
        return false
      }
    }
  }

  async function open() {
    if (await checkPaymentStatus()) {
      isEnabled.value = true
    } else {
      paymentDialog.value = true
    }
  }

  async function openAlert() {
    if (await checkPaymentStatus()) {
      alertVisible.value = true
    } else {
      paymentDialog.value = true
    }
  }

  function close(): void {
    isEnabled.value = false
  }

  function reset() {
    if (rangerClient.value) {
      rangerClient.value.disconnect()
      rangerClient.value = null
    }
    isEnabled.value = false
    alertVisible.value = false
    paymentDialog.value = false
  }

  return {
    isEnabled,
    rangerClient,
    initOrGetClient,
    open,
    close,
    alertVisible,
    reset,
    isPayedService,
    paymentDialog,
    openAlert
  }
})
